const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://smartride.dvgeo.app' : 'http://192.168.100.106:3051',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://smartride.dvgeo.app' : 'http://192.168.100.106:3051',
    api: 'api/',
    apiSocket: 'smartride::1.2',
    nameDir: 'smartride',
    package: 'app.dvgeo.smartride.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDBAdB-c0EhCjOia0fCF9mKGV_Xzwn6zx0',
    appName: 'SmartRide',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#00AEF2',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.smartride.passenger',
    playStoreDriverId: 'app.dvgeo.smartride.driver',
    appStorePassengerId: '6692633760',
    appStoreDriverId: '6692633595',
    email: "cpcjoselcp@outlook.com",
};
export default config;
